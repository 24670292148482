var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.skeleton)?_c('v-skeleton-loader',{attrs:{"height":"400px","boilerplate":"","type":"table-thead,table-tbody"}}):_vm._e(),(!_vm.skeleton)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"items-per-page":5,"headers":_vm.headers,"items":_vm.data,"item-key":"name","color":"primary"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":item.route}},[_vm._v(_vm._s(item.name))])]}},{key:"item.deficitPerDay",fn:function(ref){
var item = ref.item;
return _vm._l((item.deficitPerDay),function(values,i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":_vm.valueColor(values)}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.tooltipText(values, i))}})])})}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }