










































import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import { PlannerTableData } from "@/interfaces";
@Component
export default class PlannerTable extends Vue {
    @PropSync("tableData", { type: Array })
    data!: PlannerTableData[];

    @PropSync("line", { type: String })
    routeLine!: string;

    skeleton = false;

    headers: Dictionary<string | boolean>[] = [
        {
            text: String(this.$t("plannerTable.name")),
            align: "start",
            value: "name"
        },
        { text: String(this.$t("plannerTable.demanda")), value: "demand" },
        { text: String(this.$t("plannerTable.flow")), value: "planned" },
        { text: String(this.$t("plannerTable.deficit")), value: "deficit" },
        { text: String(this.$t("plannerTable.co2")), value: "emission" },
        { text: String(this.$t("plannerTable.income")), value: "economical" },
        {
            text: String(this.$t("plannerTable.superfical")),
            value: "superficial"
        },
        {
            text: String(this.$t("plannerTable.subterranea")),
            value: "subterranea"
        },
        {
            text: String(this.$t("plannerTable.reutilizada")),
            value: "reutilizada"
        },
        { text: String(this.$t("plannerTable.trasvase")), value: "trasvase" },
        { text: String(this.$t("plannerTable.desalada")), value: "desalada" },
        {
            text: String(this.$t("plannerTable.deficitPerDay")),
            value: "deficitPerDay",
            sortable: false
        }
    ];

    @Watch("data")
    async onDataChange() {
        if (this.data.length > 0) {
            this.loadTableData();
            this.skeleton = false;
            return;
        }
        this.skeleton = true;
    }
    selectedCategory: string | undefined = "";

    @Watch("$route.params")
    async onRouteUpdate() {
        this.selectedCategory = this.$route.params.type;
    }

    mounted() {
        if (this.data.length > 0) {
            this.loadTableData();
            this.skeleton = false;
            return;
        }
        this.skeleton = true;
    }
    valueColor(value) {
        return 0 > value ? "red" : "green";
    }

    tooltipText(value, i) {
        return `${this.data[0].dates[i]}: ${value} hm³`;
    }

    loadTableData() {
        for (let i = 0; i < this.data.length; i++) {
            let href = `/${this.routeLine}/dashboard/${this.data[i].name}`;
            if (this.$route.params.type != undefined) {
                href = `/${this.routeLine}/dashboard/${this.$route.params.type}/${this.data[i].name}`;
            }
            this.data[i]["route"] = href;
        }
    }
}
